var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_vm._v(" Marc Rufeis © "+_vm._s(_vm.year)+" - "),_c('a',{staticClass:"footerlink",attrs:{"target":"_blank","href":"https://itmr-dev.de/impressum.html"}},[_vm._v("Impress")]),_c('div',{staticClass:"footer-info",on:{"mouseover":function($event){_vm.showInfo=true},"mouseleave":function($event){_vm.showInfo=false}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInfo),expression:"showInfo"}]},[_vm._v(" Made with "),_c('i',{staticClass:"fas fa-heart"}),_vm._v(", "),_c('i',{staticClass:"fab fa-vuejs"}),_vm._v(", "),_c('i',{staticClass:"fab fa-bootstrap"}),_vm._v(" & "),_c('i',{staticClass:"fab fa-font-awesome"}),_c('span',[_vm._v(" < ")]),_c('i',{staticClass:"fas fa-info"})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showInfo),expression:"!showInfo"}]},[_c('i',{staticClass:"fas fa-info"})])]),_c('div',{staticClass:"footer-socials",on:{"mouseover":function($event){_vm.showSocials=true},"mouseleave":function($event){_vm.showSocials=false}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSocials),expression:"showSocials"}]},[_c('i',{staticClass:"fas fa-user"}),_c('span',[_vm._v(" > ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSocials),expression:"!showSocials"}]},[_c('i',{staticClass:"fas fa-user"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footerlink footersocial",attrs:{"href":"https://www.linkedin.com/in/marcrufeis/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-linkedin"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footerlink footersocial",attrs:{"href":"https://github.com/craftycram/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-github"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footerlink footersocial",attrs:{"href":"https://discord.gg/fZ4jyPj","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-discord"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footerlink footersocial",attrs:{"href":"https://twitch.tv/craftycram/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-twitch"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footerlink footersocial",attrs:{"href":"https://www.instagram.com/marcrufeis/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-instagram"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"footerlink footersocial",attrs:{"href":"https://twitter.com/craftycram/","target":"_blank","rel":"noopener noreferrer"}},[_c('i',{staticClass:"fab fa-twitter"})])
}]

export { render, staticRenderFns }