<template>
  <div class="footer">
    Marc Rufeis © {{year}}
    -
    <a target="_blank" href="https://itmr-dev.de/impressum.html" class="footerlink">Impress</a>
    <div v-on:mouseover="showInfo=true" v-on:mouseleave="showInfo=false" class="footer-info">
      <span v-show="showInfo">
        Made with <i class="fas fa-heart"></i>, <i class="fab fa-vuejs"></i>, <i class="fab fa-bootstrap"></i> &amp; <i class="fab fa-font-awesome"></i>
        <span> &lt; </span>
        <i class="fas fa-info"></i>
        </span>
      <span v-show="!showInfo"><i class="fas fa-info"></i></span>
    </div>
    <div v-on:mouseover="showSocials=true" v-on:mouseleave="showSocials=false" class="footer-socials">
      <span v-show="showSocials">
        <i class="fas fa-user"></i>
        <span> &gt; </span>
        <a href="https://www.linkedin.com/in/marcrufeis/" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a>
        <a href="https://github.com/craftycram/" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-github"></i></a>
        <a href="https://discord.gg/fZ4jyPj" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-discord"></i></a>
        <a href="https://twitch.tv/craftycram/" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitch"></i></a>
        <a href="https://www.instagram.com/marcrufeis/" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
        <a href="https://twitter.com/craftycram/" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a>
        <!--a href="" class="footerlink footersocial" target="_blank" rel="noopener noreferrer"><i class="fab fa-google-play"></i></a-->
      </span>
      <span v-show="!showSocials"><i class="fas fa-user"></i></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: '',
      showInfo: false,
      showSocials: false,
    }
  },
  mounted() {
        this.year = new Date().getFullYear();
  }
}
</script>

<style>
.footer{
  background-color: white;
}

.footerlink {
  text-decoration: none;
  color: #666666;

}

.footerlink:hover {
  text-decoration: none;
  color: #4674b8;
}

.footerlink:hover::before {
  content: none;
}

.footersocial {
  margin-right: 5px;
}

.footer-info {
  position: absolute;
  bottom: 0px;
  right:5px
}
.footer-socials {
  position: absolute;
  bottom: 0px;
  left:5px
}
</style>