<template>
  <div id="app" v-bind:style="{ backgroundImage: `url(./imgs/${$store.state.bgImg})` }">
    <meta charset="utf-8">
    <link rel="stylesheet" href="style.css">
    <link rel="shortcut icon" href="favicon.ico" type="image/x-icon">
    <link href="https://fonts.googleapis.com/css?family=Source+Code+Pro&display=swap" rel="stylesheet">

    <div>
      <div class="content">
      <canvas id="canvas"></canvas>
      <b-container class="main-area">
        <router-view name="header"></router-view>
        <router-view></router-view>
      </b-container>
      <router-view name="footer"></router-view>
    </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    components: {
    },
    mounted() {
      this.validateUser();
      this.axios.get('/')
        .then((res) => {
          this.$store.state.user = res.data.user;
          this.$store.state.repos = res.data.repos;
          this.$store.state.languageUrls = res.data.languageUrls;
          this.$store.state.languages = res.data.languages;
          this.$store.state.languagesText = res.data.languagesText;
          this.$store.state.publicRepoCount = res.data.publicRepoCount;
          this.$store.state.error = res.data.error;
          this.$store.state.liveOnTwitch = res.data.liveOnTwitch;
          if (res.data.error) {
            console.error(res.data.error);
          }
        })
        .catch((err) => {
          this.$store.state.error = err;
          console.error(err);
        });
    },
    methods: {
      async validateUser() {
        const token = await localStorage.getItem('token')
        this.axios
          .get('auth/validate', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((r) => {
            this.$store.state.auth.loggedIn = true
            this.$store.state.auth.id = r.data.id
            this.$store.state.auth.preName = r.data.preName
            this.$store.state.auth.lastName = r.data.lastName
            this.$store.state.auth.email = r.data.email
            this.$store.state.auth.admin = r.data.admin
          })
          .catch(() => {
            this.$store.state.auth.loggedIn = false
          })
      },
    }
  }
</script>

<style>
* {
  margin: 0;
}

#app {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

body {
  font-family: 'Source Code Pro', monospace;
  color: #333333;
}

.writing {
  font-size: 600%;
  animation:
    type 2s steps(14),
    after 1s 3s infinite;
  overflow: hidden;
  white-space: nowrap;
  border-right: #333333 4px solid;
  width: 14ch;
}

.typing {
  font-size: 600%;
  white-space: nowrap;
}

.typed-cursor {
  font-size: 600%;
  /*overflow: hidden;
  white-space: nowrap;*/
}

@keyframes type {
  0% {
    width: 0ch;
  }
  100% {
    width: 14ch;
  }
}

@keyframes after {
  0% {
    border-right: #333333 4px solid;
  }
  50% {
    border-right: none;
  }
  100% {
    border-right: #333333 4px solid;
  }
}

h1 {
  font-size: 600%;
}

.content {
  width: 100vw;
  height: 100vh;
}

.main-area {
  padding: 10px;
  padding-top: 10%;
}

a {
  text-decoration: none;
  color: #3b3b3b;

}

a:hover {
  text-decoration: none;
  color: #4674b8;
  background-color: white;
}

a:hover::before {
  content:'> ';
}

#twitch {
  color: #9146FF;
}
#twitch:hover {
  color: #4674b8;
}

#back:hover::before {
  content: none;
}

#hyperlink {
  text-decoration: underline;
  cursor: pointer;
}
#hyperlink:hover {
  text-decoration: none;
  color: #4674b8;
  background-color: white;
}
#hyperlink::before {
  content: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;;
  color: #666666;
  text-align: center;
}

#canvas {
  width: 100vw;
  height: 100vh;
  position: absolute;
  pointer-events: none;
}

#image {
  background-image: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
