import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueClipboard from 'vue-clipboard2'


import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import deDE from '@kangc/v-md-editor/lib/lang/de-DE';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router/router'
import VueTypedJs from 'vue-typed-js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHeart, faInfo, faUser, faQrcode, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome, faInstagram, faLinkedin, faGithub, faVuejs, faTwitch, faTwitter, faDiscord, faGooglePlay, faBootstrap } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { dom } from '@fortawesome/fontawesome-svg-core'
import store from './store'

import Prism from 'prismjs';
// highlight code
import 'prismjs/components/prism-json';
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
VueMarkdownEditor.lang.use('de-DE', deDE);
Vue.use(VueMarkdownEditor);

dom.watch();
library.add(faHeart, faInfo, faFontAwesome, faUser, faQrcode, faPencilAlt, faInstagram, faLinkedin, faGithub, faVuejs, faTwitch, faTwitter, faDiscord, faGooglePlay, faBootstrap);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueTypedJs)

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://marcrufeis.glados.itmr-dev.de/' : 'http://10.10.1.37:3003/';
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    toast(title, text, variant = null) {
      this.$bvToast.toast(text, {
        title,
        variant,
          solid: true,
          autoHideDelay: 2000,
          toaster: 'b-toaster-top-right',
          appendToast: false,
      });
    },
  },
});


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') === null) {
      next({
        path: '/login',
      });
      store.state.loggedIn = false;
    } else {
      next();
      store.state.loggedIn = true;
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
