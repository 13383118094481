<template>
  <div>
    <p>I…</p>
    <p><router-link to="/about">…am Marc</router-link></p>
    <p><router-link to="/projects">…did those projects</router-link></p>
    <p><router-link to="/studies">…currently study</router-link></p>
    <p><router-link to="/socials">…have a lot of accounts</router-link></p>
    <p><a id="email" href="mailto:hello@marcrufeis.de" :style="{ color: birthday ? '#FFD700' : '' }">
      {{birthday ? '…send me some birthday wishes 🥳' : '…will answer your email'}}
    </a></p>
    <p v-if="$store.state.liveOnTwitch"><a id="twitch" href="https://twitch.tv/craftycram/" target="_blank">…am currently live on Twitch</a></p>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        birthday: false,
      };
    },
    mounted() {
      const now = new Date();
      if (now.getMonth() === 2 && now.getDate() === 8) this.birthday = true; // 8th march
    },
  }
</script>

<style scoped>
p {
  color: #3b3b3b;
  font-size: 300%;
  margin: 0;
}
</style>