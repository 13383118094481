<template>
  <vue-typed-js
        :strings="['Hi, I am Marc!', 'I\'m a problem solver.', 'I\'m a nerd.', 'I\'m an entrepreneur.', /*'and your next business partner.'*/]"
        :typeSpeed="100"
        :backSpeed="80"
        :backDelay="2000"
        :startDelay="500"
        :loop="true"
        cursorChar="_">
          <h1 class="typing"></h1>
        </vue-typed-js>
</template>

<script>
  export default {
    name: 'Header',
  }
</script>

<style>

</style>