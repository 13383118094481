import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      header: Header,
      default: Home,
      footer: Footer
    }
  },
  {
    path: '/about',
    name: 'About',
    components: {
      header: Header,
      default: () => import('../views/About.vue'),
      footer: Footer
    }
  },
  {
    path: '/studies',
    name: 'Studies',
    components: {
      header: Header,
      default: () => import('../views/Studies.vue'),
      footer: Footer
    }
  },
  {
    path: '/activities',
    name: 'Activities',
    components: {
      header: Header,
      default: () => import('../views/Activities.vue'),
      footer: Footer
    }
  },
  {
    path: '/socials',
    name: 'Socials',
    components: {
      header: Header,
      default: () => import('../views/Socials.vue'),
      footer: Footer
    }
  },
  {
    path: '/projects-old',
    name: 'Projects',
    components: {
      header: Header,
      default: () => import('../views/ProjectsOld.vue'),
      footer: Footer
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    components: {
      header: Header,
      default: () => import('../views/Projects.vue'),
      footer: Footer
    }
  },
  {
    path: '/plugins',
    name: 'VS Code Plugins',
    components: {
      // header: Header,
      default: () => import('../views/VSCodePlugins.vue'),
      footer: Footer
    }
  },
  {
    path: '/pcconfig',
    alias: '/pc-config',
    name: 'PC Config',
    components: {
      // header: Header,
      default: () => import('../views/PCConfig.vue'),
      footer: Footer
    }
  },
  {
    path: '/credits',
    name: 'Credits',
    components: {
      // header: Header,
      default: () => import('../views/Credits.vue'),
      footer: Footer
    }
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      // header: Header,
      default: () => import('../views/Login.vue'),
      footer: Footer
    }
  },
  {
    path: '/reset',
    name: 'Reset',
    components: {
      // header: Header,
      default: () => import('../views/Reset.vue'),
      footer: Footer
    }
  },
  {
    path: '/secrets/:id',
    name: 'SecretsEdit',
    components: {
      // header: Header,
      default: () => import('../views/SecretsEdit.vue'),
      footer: Footer
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/secrets',
    name: 'Secrets',
    components: {
      // header: Header,
      default: () => import('../views/Secrets.vue'),
      footer: Footer
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/code/:code',
    name: 'Code',
    components: {
      // header: Header,
      default: () => import('../views/Code.vue'),
      footer: Footer
    },
  },
  {
    path: '/code',
    redirect: '/'
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
